const Option = ({
  icon,
  text,
  onClick,
  width = "100%",
}: {
  icon: string;
  text: string;
  onClick: () => void;
  width?: string;
}) => (
  <div
    className="padding--y--s padding--x--xs option"
    role="button"
    onKeyUp={({ key }) => {
      if (key === "Enter") onClick();
    }}
    tabIndex={0}
    onClick={onClick}
    style={{ width: width }}
  >
    <span className={icon} />
    <span className="margin--left--xs">{text}</span>
  </div>
);

export default Option;
