import React from "react";
// we seem to have trouble loading types from classcat
// @ts-ignore
import cc from "classcat";
import { Row } from "@narmi/design_system";
const CardTitle = ({ title }: { title: string }) => (
  <div className="fontWeight--semibold fontColor--theme--primary margin--bottom--l">{title}</div>
);

export const InfoRow = ({
  name,
  value,
  expandKey = false,
  children,
}: React.PropsWithChildren<{
  name: string;
  value?: string;
  expandKey?: boolean;
}>) => {
  if (!children && !value && value !== "") return null;
  return (
    <div
      className={cc([
        `info-row${expandKey ? "-expanded-key" : ""}`,
        "fontSize--s",
        { "margin--top--s": name && (value || children) },
        { "margin--top--xs": !(name && (value || children)) },
      ])}
    >
      {name && <div className="fontColor--heading fontWeight--semibold">{name}</div>}
      <div className="info-row-value">{value ?? children}</div>
    </div>
  );
};

type Data = { [key: string]: string } | { [key: string]: string }[];

const renderContent = (
  data: Data,
  useWhiteBackgroundCards: boolean,
  expandKey: boolean = false,
  action?: React.ReactNode
): React.ReactNode => {
  if (Array.isArray(data)) {
    return data.map((d) => renderContent(d, useWhiteBackgroundCards, expandKey, action));
  }

  if (useWhiteBackgroundCards) {
    return (
      <>
        {Object.entries(data).map(([key, value]) => {
          if (Array.isArray(value)) {
            return Object.entries(value).map(([rowIndex, rowValue]) => (
              <InfoRow
                key={`${key}${rowIndex}`}
                name={rowIndex.toString() === "0" ? key : ""}
                value={rowValue}
              />
            ));
          }
          if (action) return value;
          return <InfoRow key={key} name={key} value={value} expandKey={expandKey} />;
        })}
      </>
    );
  }

  return (
    <div className="shaded-container margin--top--s">
      {Object.entries(data).map(([key, value], idx) => {
        if (idx === 0 && key === "Card Title") return <CardTitle title={value} />;
        if (Array.isArray(value)) {
          return Object.entries(value).map(([rowIndex, rowValue]) => (
            <InfoRow
              key={`${key}${rowIndex}`}
              name={rowIndex.toString() === "0" ? key : ""}
              value={rowValue}
            />
          ));
        }
        return <InfoRow key={key} name={key} value={value} />;
      })}
    </div>
  );
};

type Getter =
  | {
      [key: string]:
        | { getValue: () => any; formatValue?: (value: any) => any }
        | { render: () => React.ReactNode };
    }
  | {
      [key: string]:
        | { getValue: () => any; formatValue?: (value: any) => any }
        | { render: () => React.ReactNode };
    }[]
  | null;

// create a flattened data object/array from getter function and application
const flattenData = (data: Getter): Data => {
  if (Array.isArray(data)) {
    return data.map(flattenData) as { [key: string]: string }[];
  }
  if (!data) return {};
  return Object.fromEntries(
    Object.entries(data)
      .filter(([, info]) => {
        return "render" in info || ![null, undefined, ""].includes(info.getValue());
      })
      .map(([name, info]) => {
        if ("render" in info) {
          return [name, info.render()];
        }
        const { formatValue } = info;
        const value = info.getValue();
        return [name, formatValue ? formatValue(value) : value];
      })
  );
};

const InfoSection = ({
  title,
  action,
  data,
  contentBeforeTable = <></>,
  useWhiteBackgroundCards = false,
  expandKey = false,
}: {
  title?: string;
  action?: React.ReactNode;
  data: any;
  contentBeforeTable?: React.ReactNode;
  useWhiteBackgroundCards?: boolean;
  expandKey?: boolean;
}) => {
  if (!data || !Object.keys(data).length) return null;
  return (
    <InfoSection.Compositional
      title={title}
      action={action}
      useWhiteBackgroundCards={useWhiteBackgroundCards}
    >
      {contentBeforeTable}
      {renderContent(data, useWhiteBackgroundCards, expandKey, action)}
    </InfoSection.Compositional>
  );
};

InfoSection.Compositional = ({
  title,
  action,
  useWhiteBackgroundCards = false,
  backgroundMarginY = "xl",
  children,
}: React.PropsWithChildren<{
  title?: string;
  action?: React.ReactNode;
  useWhiteBackgroundCards?: boolean;
  backgroundMarginY?: "xxl" | "xl" | "l" | "m" | "s" | "xs" | "xxs" | "";
}>) => {
  if (children === null || children === undefined) return null;
  if (useWhiteBackgroundCards) {
    return (
      <div className={`shaded-container-white-bg margin--y--${backgroundMarginY}`}>
        {(title || action) && (
          <Row>
            <Row.Item>
              <h4 className="fontFamily--body margin--bottom--l">{title}</h4>
            </Row.Item>
            <Row.Item shrink>{action}</Row.Item>
          </Row>
        )}
        <div className="info-section-white-bg">{children}</div>
      </div>
    );
  }

  return (
    <div className="info-section">
      {(title || action) && (
        <div className="header-row margin--top--xl ">
          <h4 className="fontFamily--body">{title}</h4>
          {action}
        </div>
      )}
      {children}
    </div>
  );
};

InfoSection.flattenData = flattenData;
export default InfoSection;
