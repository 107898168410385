import React, { Fragment, ReactNode } from "react";
import { RadioButtons } from "@narmi/design_system";

const Label = ({ children }: { children: ReactNode }): ReactNode => (
  <div
    className="margin--top--xl fontWeight--bold fontSize--l"
    style={{ color: "RGB(var(--nds-black))" }}
  >
    {children}
  </div>
);

const SubLabel = ({ children }: { children: ReactNode }): ReactNode => (
  <div className="margin--top--xs margin--right--l">{children}</div>
);

const ButtonGroup = ({
  name,
  options,
  value,
  onChange,
}: {
  name: string;
  options: object;
  value: string;
  onChange: (e: any) => void;
}) => {
  return (
    <div className="margin--top--l">
      <RadioButtons kind="card" name={name} options={options} value={value} onChange={onChange} />
    </div>
  );
};

/**
 * groups: label, subLabel, options
 */
const RadioButtonGroups = ({
  name,
  groups,
  value,
  onChange,
  error,
}: {
  name: string;
  value: string;
  groups: {
    label: string;
    subLabel: string;
    options: {
      [x: string]: object;
    };
  }[];
  onChange: (e: any) => void;
  error: string;
}) => (
  <div className="radio-button-groups">
    {groups.map((group, i) => (
      <Fragment key={i}>
        <Label>{group.label}</Label>
        <SubLabel>{group.subLabel}</SubLabel>
        <ButtonGroup name={name} options={group.options} value={value} onChange={onChange} />
      </Fragment>
    ))}
    {error && <div className="nds-input-error">{error}</div>}
  </div>
);

export default RadioButtonGroups;
